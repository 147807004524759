import $ from 'jquery';
import 'slick-carousel/slick/slick';
import WOW from 'wow.js/dist/wow.js';
import './modules/header.js';
import './modules/footer.js';
import fullpage from 'fullpage.js/dist/fullpage'

if($("#top").length > 0){
    var kv = window.sessionStorage.getItem("kv");
    if (!kv){
    $("body").css({
        "position":"fixed",
        "overflow-y":"scroll"
    });
        if($(".show-pc").is(":visible")){
            $('.message-box').addClass('wow slideInLeft');
            $('.message-box').attr({
                'data-wow-duration':'0.6s',
                'data-wow-delay':'1.3s'
            });
            setTimeout(function () {
                $('.start-page').fadeOut();
            },1000);
        }else{
            setTimeout(function () {
                $('.start-page').fadeOut();
            },1000);
        }
        new WOW().init();
        window.sessionStorage.setItem("kv", 1);
        setTimeout(function () {
            $('body').attr('style','');
        },1000);
        setTimeout(function () {
            $('header').attr('style','').removeClass("wow slideInDown");
        },1000);
    }
    $(".start-page").hide();
    $( window ).resize(function() {
        $(".scroll-text span").css("-webkit-animation-play-state", "paused");
        setTimeout(function (){
            $(".scroll-text span").css("-webkit-animation-play-state", "running");
        },100)
    });
}
$(function(){
    $(".header").removeClass("hide");
    if($("#admt").length > 0){
        new WOW().init();
    }
    if($(".show-pc").is(":visible")){
        if($("#top").is(":visible")){
            var position = localStorage.getItem('position');
            $(window).scrollTop(position,function () {
                var scroll = window.scrollY + $(window).height();
                var block1 = $("#business-content").offset().top;
                var block2 = $("#book-content").offset().top;
                $(".message-box, #head-text").removeClass("wow slideInLeft");
                $(".message-box, #head-text").attr('style','');
                if($(".top-en").length > 0){
                    if(scroll >= block2 + 200){
                        $("#head-text").removeClass("show");
                        $(".img-box").addClass("show");
                    }else{
                        $("#head-text").addClass("show");
                        $(".img-box").removeClass("show");
                    }
                }else{
                    var block3 = $("#news-content").offset().top;
                    if(scroll >= block2 + 500 && scroll < block3 + 200){
                        $("#head-text").removeClass("show");
                        $(".img-box").addClass("show");
                    }else{
                        $("#head-text").addClass("show");
                        $(".img-box").removeClass("show");
                    }
                    var scrolltop = window.scrollY;

                    var imgbox = scrolltop + $(".img-box img").outerHeight(true) + parseInt($("header.scrolled").outerHeight());
                    if(imgbox > $(".gray-box").offset().top){
                        $(".img-box").addClass("absolute");
                    }else{
                        $(".img-box").removeClass("absolute")
                    }
                }
                if(scroll >= block1 + 200){
                    $(".message-box").addClass("hide");

                }else{
                    $(".message-box").removeClass("hide");
                }
                if(scroll >= $("footer").offset().top){
                    var padding = scroll - $("footer").offset().top;
                    $("#head-text").css({"top": "auto", "bottom":padding + "px"});
                }else{
                    $("#head-text").attr("style");
                }

            });
        }
        $(window).on("scroll",function(){
            if($("#top").is(":visible")){
                localStorage.setItem('position', window.scrollY);
                var scroll = window.scrollY + $(window).height();
                var block1 = $("#business-content").offset().top;
                var block2 = $("#book-content").offset().top;
                $(".message-box, #head-text").removeClass("wow slideInLeft");
                $(".message-box, #head-text").attr('style','');
                if($(".top-en").length > 0){
                    if(scroll >= block2 + 200){
                        $("#head-text").removeClass("show");
                        $(".img-box").addClass("show");
                    }else{
                        $("#head-text").addClass("show");
                        $(".img-box").removeClass("show");
                    }
                }else{
                    var block3 = $("#news-content").offset().top;
                    if(scroll >= block2 + 500 && scroll < block3 + 200){
                        $("#head-text").removeClass("show");
                        $(".img-box").addClass("show");
                    }else{
                        $("#head-text").addClass("show");
                        $(".img-box").removeClass("show");
                    }
                    var scrolltop = window.scrollY;
                    var imgbox = scrolltop + $(".img-box img").outerHeight(true) + parseInt($("header.scrolled").outerHeight());
                    if(imgbox > $(".gray-box").offset().top){
                        $(".img-box").addClass("absolute");
                    }else{
                        $(".img-box").removeClass("absolute")
                    }
                }
                if(scroll >= block1 + 200){
                    $(".message-box").addClass("hide");

                }else{
                    $(".message-box").removeClass("hide");
                }
                if(scroll >= $("footer").offset().top){
                    var padding = scroll - $("footer").offset().top;
                    $("#head-text").css({"top": "auto", "bottom":padding + "px"});
                }else{
                    $("#head-text").attr("style");
                }

            }
        });
    }
    $(".description dt").on("click",function () {
        if($(this).closest(".description").hasClass("open")){
            $(this).closest(".description").removeClass("open");
            $(this).closest(".description").find("dd").slideUp();
        }else{
            $(this).closest(".description").addClass("open");
            $(this).closest(".description").find("dd").slideDown();
        }
    });
    $(".question dt").on("click",function () {
        if($(this).closest(".question").hasClass("open")){
            $(this).closest(".question").removeClass("open");
            $(this).closest(".question").find("dd").slideUp();
        }else{
            $(this).closest(".question").addClass("open");
            $(this).closest(".question").find("dd").slideDown();
        }
    });
    $(".tab-control li a").on("click",function (e) {
        $(this).closest(".tab-control").find(".active").removeClass("active");
        $(this).closest("li").addClass("active");
        var target = $(this).attr("href");
        if(target.indexOf("#")>= 0){
            e.preventDefault();
            $(".tab-content.active").fadeOut(function () {
                $(".tab-content.active").removeClass("active");
                $(target).fadeIn().addClass("active");
            })
        }
    });
    $(".page-anchor li a").on("click",function (e) {
        var target = $(this).attr("href");
        if(target!== "" && target!== "#" && target.indexOf("/") < 0){
        e.preventDefault();
        $(this).closest(".page-anchor").find(".active").removeClass("active");
        $(this).closest("li").addClass("active");
            if($(target)){
                var paddingtop = $("header.scrolled").outerHeight()*1 + $(".page-anchor").outerHeight()*1 + 20;
                $('html, body').animate({scrollTop : $(target).offset().top - paddingtop},800);
            }
        }
    });
    $(".anchor .anchor-control a").on("click",function (e) {
        var target = $(this).attr("href");
        if(target!== "" && target!== "#" && target.indexOf("/") < 0){
            e.preventDefault();
            $(this).closest(".anchor").find(".active").removeClass("active");
            $(this).closest("li").addClass("active");
            if($(target)){
                $('html, body').animate({scrollTop : $(target).offset().top},800);
            }
        }
    });
    var scrolltop = 0;
    $(".top-slideshow a, .show-all").on("click",function (e) {
        e.preventDefault();
        $(".modal").addClass("show");
        scrolltop = window.scrollY;
    });
    $(".profile .img-box >a").on("click",function (e) {
        e.preventDefault();
        $(this).closest(".about").find(".modal").addClass("show");
        scrolltop = window.scrollY;
    });
    $("a.post-image").on("click",function (e) {
        e.preventDefault();
        $(this).closest(".col").find(".modal").addClass("show");
        scrolltop = window.scrollY;
    });
    $(".modal, .close-button").on("click",function (e) {
        e.preventDefault();
        if($(e.target).is(".modal, .close-button")){
            $(".modal").removeClass("show");
        }
    })
    $('.modal .top-slideshow').on('afterChange', function(event, slick, currentSlide, nextSlide){
        var total = $('.modal .top-slideshow .item').length;
        $(".modal .count").text(currentSlide+1 + "/" + total);
    });
    $(".step-box .box-button a").on("click",function (e) {
            e.preventDefault();
            $(".step-box .box-button .active").removeClass("active");
            $(this).addClass("active");
            var target = $(this).attr("href");
            if(target !== "" && target!== "#"){
                $(".step-box .step-wrap.active").removeClass("active");
                $(target).addClass("active");
                $(".big-button .button.active").removeClass("active");
                var button = target.replace("#",".");
                $(button).addClass("active");
            }

    });
    $("#application-outline .big-button .button a").on("click",function (e) {
        e.preventDefault();
        $("#application-outline .big-button .active").removeClass("active");
        $(this).closest(".button").addClass("active");
    });
    $(window).on("load resize ",function(e){
        if($(".show-sp").is(":visible")){
            $("#sitemap .row .parent").attr("style","");
            $("#sitemap .row .parent").each(function () {
                var that = $(this);
                $("#sitemap .row .parent").each(function () {
                    if($(this).offset().top == that.offset().top){
                        if($(this).outerHeight() < that.outerHeight()){
                            $(this).css("height", that.outerHeight());
                        }else if($(this).outerHeight() > that.outerHeight()){
                            that.css("height", $(this).outerHeight());
                        }
                    }
                })
            })
        }
    });

    if($(".top-slideshow")){
        $(".top-slideshow").slick({
            speed: 500,
            slidesToShow: 1,
            arrows: true,
            slidesToScroll: 1,
            infinite: false,
            asNavFor: '.linkslider',
        });
    }
    if($(".bottom-slideshow")){
        var slidecount = $(".bottom-slideshow .item").length;
        $(".bottom-slideshow").slick({
            dots: false,
            slidesToShow: slidecount,
            infinite: false,
            autoplay: false,
            asNavFor: '.linkslider',
            focusOnSelect: true
        });
    }
    // $(".list .pdf a").on("click",function (e) {
    //     if($(this).attr("data-iframe")){
    //         e.preventDefault();
    //         var target = $(this).attr("data-iframe");
    //         var href =  $(this).attr("href");
    //         $(target).attr("src",href);
    //     }
    // });
});
// Back to top
$(window).scroll(function(){
    // Show button after 100px
    var showAfter = 100;
    if (window.scrollY > showAfter ) {
        $('.go-top').css("display","flex");
    } else {
        $('.go-top').attr("style","");
    }
    var windowheight = $(window).height();
    if($( window ).width() > 768) {
        if ($(".two-col") && !$(".two-col").is("#privacy") && !$(".two-col").is("#404") && !$(".two-col").is("#outline")) {
            if (window.scrollY + windowheight >= $("footer").offset().top) {
                $("h1").addClass("absolute");
            } else {
                $("h1").removeClass("absolute");
            }
        }
    }
    var scrolltop = window.scrollY;
    if($(".page-anchor")){
        $(".page-anchor ul li").each(function () {
            var target = $(this).find("a").attr("href");
            if(target!== "" && target!== "#" && target.indexOf("/") < 0){
                var padding = parseInt($("header.scrolled").outerHeight() + $(".page-anchor").outerHeight()) + 100;
                var current = parseInt(scrolltop) + padding;
                var position = Math.round($(target).offset().top);
                if(current >= position && current <= position + 100){
                    $(".page-anchor .active").removeClass("active");
                    $(this).addClass("active");
                }if(current <= position && current > position - 100){
                    $(".page-anchor .active").removeClass("active");
                    $(this).prev().addClass("active");
                }
            }
        })
    }
});

if($("#outline-history").length > 0){
    $('.right-box .milestones .time, .right-box .milestones .detail').attr({
        'data-wow-duration':'0.5s',
        'data-wow-delay':'0.2s',
        'data-wow-offset': 200
    });
    $(".right-box .milestones .time").addClass('wow fadeInLeft');

    $(".right-box .milestones .detail").addClass('wow fadeInRight');

    new WOW().init();

    $(window).scroll(function(){
        var windowheight = $(window).height();
        if($( window ).width() > 768) {
            var bottom = 0;
            if($(".main>.button-block").length > 0){
                bottom = $(".main>.button-block").offset().top;
            }else{
                bottom = $(".footer").offset().top;
            }
            if (window.scrollY + windowheight >= bottom) {
                $(".left-box").addClass("absolute");
            } else {
                $(".left-box").removeClass("absolute");
            }
        }
        var padding = $(".content").offset().top + 3 - window.scrollY;
        if(padding > 100){
            $(".left-box").css("padding-top",padding);
        }else{
            $(".left-box").css("padding-top",100);
        }

        var scrolltop = window.scrollY;
        $(".right-box .timeline .line").css({"height": scrolltop});
        $(".left-box .timeline .milestones .time a").each(function () {
            var target = $(this).attr("href");
            if(target!== "" && target!== "#"){
                var padding = parseInt($("header.scrolled").outerHeight()) + 100;
                var current = parseInt(scrolltop) + padding + 200;
                var position = Math.round($(target).offset().top);
                if(current >= position && current <= position + 100){
                    $(".active").removeClass("active");
                    $(".current").removeClass("current");
                    $(this).closest(".time").addClass("active");
                    $(target).closest(".milestones").addClass("current");
                }if(current <= position && current > position - 100){
                    if(!$(".active").closest(".milestones").is(":first-child")){
                        $(".active").removeClass("active");
                        $(".current").removeClass("current");
                        $(this).closest(".milestones").prev().find(".time").addClass("active");
                        $(target).closest(".milestones").prev().addClass("current");
                    }
                }
            }
        })
    });
    $(".left-box .time a").on("click",function (e) {
        e.preventDefault();
        var target = $(this).attr("href");
        if($(target)){
            var paddingtop = $("header.scrolled").outerHeight()*1;
            $('html, body').animate({scrollTop : $(target).offset().top - 200 - paddingtop},800);
        }
    })
}
if($("#outline-biography").length > 0){
    $(window).scroll(function(){
        if($( window ).width() > 768) {
            var windowheight = $(window).height();
            if (window.scrollY + windowheight >= $("footer").offset().top) {
                $(".image").addClass("absolute");
            } else {
                $(".image").removeClass("absolute");
            }
            if (window.scrollY >= 80) {
                $(".first-view .content-box").css("padding-top", $("header.scrolled").outerHeight() * 1);
            } else {
                $(".first-view .content-box").attr("style", "");
            }
            var scrolltop = window.scrollY;
            $("section").each(function () {
                if(scrolltop >= $(this).offset().top){
                    $(this).addClass("done");
                }
            })
            if(scrolltop + windowheight >= $("footer").offset().top){
                $(document).find("section:nth-last-child(2)").addClass("footeractive");
            }else{
                $(document).find("section:nth-last-child(2)").removeClass("footeractive");
            }
        }
    });
    $("a.next").on("click",function (e) {
        e.preventDefault();
        var target = $(this).attr("href");
        if($(target)){
            var paddingtop = $("header.scrolled").outerHeight()*1;
            $('html, body').animate({scrollTop : $(target).offset().top - paddingtop},800);
        }
    })

}

if($("#outline-biography").length > 0){
    $(".footer").addClass("fp-auto-height");
    if($( window ).width() > 768){
        $("#fullpage").append("<section class='section fp-auto-height'></section>");
        $("#fullpage").find("section.fp-auto-height:last-child").append($("footer"));
        new fullpage('#fullpage', {
            //options here
            autoScrolling:false,
            scrollHorizontally: true,
            navigation: true,
            navigationPosition: 'right',
            fitToSectionDelay: 500,
        });

    }
    $(".content-box .title, .content-box .subtitle, .content-box .line, .content-box > p, .content-box .list , .content-box .button-block").each(function () {
        var count = $(this).index();
        $(this).attr({
            'data-wow-duration':'0.5s',
            'data-wow-delay': count*100 + "ms",
            'data-wow-offset': 200
        });
    });
    $(".content-box .title, .content-box .subtitle,.content-box .line, .content-box > p, .content-box .list , .content-box .button-block").addClass("wow fadeIn");
    $(".content-box .timeline .detail").addClass("wow fadeInRight");
    $(".content-box .timeline .detail").each(function () {
        var count = $(this).closest(".milestones").index();
        $(this).attr({
            'data-wow-duration':'0.5s',
            'data-wow-delay': count*100 + "ms",
            'data-wow-offset': 200
        });
    });
    new WOW().init();
}
if($("#application").length > 0){
    var today = new Date();
    var todayparse = Date.parse(today);
    $(".milestones .time").each(function () {
        var endate = $(this).attr("data-time-end");
        if(endate){
            var endateparse = Date.parse(endate);
            if(todayparse <= endateparse){
                var startdate = $(this).attr("data-time-start");
                var startdateparse = Date.parse(startdate);
                if(startdateparse <= todayparse){
                    $(this).closest(".milestones").addClass("pink");
                }
            }
        }
    });
    $(".timeline").each(function () {
        if($(this).find(".pink").length == 0){
            var array = [];
            $(this).find(".time").each(function () {
                var endate = $(this).attr("data-time-end");
                if(endate){
                    var endateparse = Date.parse(endate);
                    if(todayparse <= endateparse){
                        var startdate = $(this).attr("data-time-start");
                        var startdateparse = Date.parse(startdate);
                        array.push(startdateparse);
                    }
                }
            });
            var min = Math.min.apply( Math, array );
            $(this).find(".time").each(function () {
                var startdate = $(this).attr("data-time-start");
                var startdateparse = Date.parse(startdate);
                if(startdateparse == min){
                    $(this).closest(".milestones").addClass("pink");
                }
            });
        }
    });
}
var target = window.location.hash;
if(target!== "" && target!== "#" && target.indexOf("/") < 0){
    window.scrollTo(0, 0);
    if($(target)){
        var anchor = 0;
        if($(".page-anchor").length > 0){
            anchor = $(".page-anchor").outerHeight()*1
        }
        var paddingtop = $("header.scrolled").outerHeight()*1 + anchor + 20;
        $('html, body').animate({scrollTop : $(target).offset().top - paddingtop},800);
    }
}
if($("#aid").length > 0){
    $(".dropdown__item a").on("click",function (e) {
        var target = $(this).attr("href");
        if(target!== "" && target!== "#" && target.indexOf("#") > 0){
            e.preventDefault();
            target = "#" + target.split('#')[1];
            if($(target)){
                var paddingtop = $("header.scrolled").outerHeight()*1 + $(".page-anchor").outerHeight()*1 + 20;
                $('html, body').animate({scrollTop : $(target).offset().top - paddingtop},800);
            }
        }
    });
}
