import $ from 'jquery';

$(function() {
    $('.menu__trigger').on('click', function() {
        $('.menu').toggleClass('open')
    })

    $('.dropdown').on('click', function(e) {
        if ($(e.target).hasClass('icon-dropdown')) {
            e.preventDefault();
            $(this).toggleClass('open');
        }

        // if ($(e.target).hasClass('icon-dropdown')) { }
    })
    $(window).on('scroll load', function() {
        var height =  $('.header').not(".scrolled").outerHeight();
        if (window.scrollY > 0) {
            $('.header.scrolled').addClass('show');
            if($(".page-anchor")){
                $(".page-anchor").addClass('scrolled');
            }
        } else {
            $('.header.scrolled').removeClass('show');
            if($(".page-anchor")){
                $(".page-anchor").removeClass('scrolled');
            }
        }
    })
    $('.dropdown').mouseover(function(e) {
        if ($(this).hasClass('dropdown')) {
            $(this).closest(".header").find(".dropdown-bg").addClass("display");
        }
    })
    $('.dropdown').on('mouseleave', function(e) {
        $(this).closest(".header").find(".dropdown-bg").removeClass("display");
    })
})
