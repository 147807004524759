import $ from 'jquery';

$(function() {
    $('.go-top').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 'slow');
    })
})
$(window).scroll(function(){
    var scrolltop = window.scrollY + $(window).height();
    var footer = $("footer").offset().top - 20;
    if(scrolltop >= footer){
        $(".go-top").addClass("fixed");
    }else{
        $(".go-top").removeClass("fixed");
    }
});